import React, { memo, useEffect, useRef, useState } from "react";
import Card from "../components/card/card";
import { motion, useInView } from "framer-motion";
import { getDatabyCollection } from "../firebase/firemethods";

const Projects = () => {
  const [toggleTab, settoggleTab] = useState(false);
  const [projects, setprojects] = useState([]);
  // { name: "", type: "", desc: "", tags: [] },
  const ref = useRef();
  const viewPort = useInView(ref);
  useEffect(() => {
    getAllprojects();
  }, []);

  async function getAllprojects() {
    const { data } = await getDatabyCollection({ collectionName: "portfolio" });
    if (data) {
      data.forEach((doc) => {
        setprojects((prev) => [...prev, { ...doc.data(), id: doc.data() }]);
      });
    }
  }

  return (
    <div className="w-full h-full py-4 px-14">
      <div className="flex justify-center py-2 my-6 items-center">
        <div className="outer-shadow flex h-12 border-2 rounded-md justify-center items-center">
          <div
            onClick={() => settoggleTab(!toggleTab)}
            className={`${
              !toggleTab ? "inner-shadow active rounded-s-md" : "outer-shadow"
            } cursor-pointer h-full font-bold px-4 justify-center items-center flex text-lg tracking-wider`}
          >
            company
          </div>
          <div
            onClick={() => settoggleTab(!toggleTab)}
            className={`${
              toggleTab ? "inner-shadow active rounded-e-md" : "outer-shadow"
            } cursor-pointer h-full px-4 font-bold justify-center items-center flex text-lg tracking-wider`}
          >
            Personal
          </div>
        </div>
      </div>
      <div className="w-full h-full p-4 gap-4 flex flex-wrap justify-center items-start">
        {!toggleTab ? (
          <div ref={ref} className="w-full">
            <motion.div
              className="w-full gap-4 flex flex-wrap justify-center items-start"
              initial={{ opacity: 0, display: "none" }}
              animate={{
                display: viewPort ? "flex" : "none",
                opacity: viewPort ? 1 : 0,
              }}
              transition={{ display: 0.6, ease: "backIn" }}
            >
              {projects.map((value, index) => {
                if (value?.type === "company") {
                  return (
                    <Card
                      key={index}
                      desc={value?.desc}
                      name={value?.name}
                      tags={value?.tags || []}
                    />
                  );
                }
              })}
            </motion.div>
            {!projects.length && (
              <div className="uppercase text-lg text-color">
                no project found.
              </div>
            )}
          </div>
        ) : (
          <div className="w-full gap-4 flex flex-wrap justify-start items-start">
            {projects.map((value, index) => {
              if (value?.type === "personal") {
                return (
                  <Card
                    key={index}
                    desc={value?.desc}
                    name={value?.name}
                    tags={value?.tags || []}
                  />
                );
              }
            })}

            {!projects.length && (
              <div className="uppercase text-lg text-color">
                no project found.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Projects);
