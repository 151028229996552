import React, { memo, useEffect, useState } from "react";
import { MdSettings } from "react-icons/md";
import { motion } from "framer-motion";
import {
  FaGithub,
  FaInstagram,
  FaVolumeHigh,
  FaVolumeXmark,
} from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter, FaDiscord } from "react-icons/fa6";
import Header from "../header/header";
import WavePlayer from "../waveEffect/wave.player";
import { Outlet, useLocation } from "react-router-dom";
import _instance from "../../utils/Web.player";
import Text from "../text/Text";
import { TbWorldWww } from "react-icons/tb";
import { getAllData } from "../../firebase/firemethods";

const Layout = () => {
  const location = useLocation();
  const [toggleTab, settoggleTab] = useState("1");
  const [getmenu, setgetmenu] = useState(false);
  const [muteSpeaker, setmuteSpeaker] = useState(false);
  const [socialLinks, setsocialLinks] = useState({
    discord: "",
    x: "",
    instagram: "",
    linkedin: "",
    website: "",
    github: "",
  });

  useEffect(() => {
    getallLinks();
  }, []);

  async function getallLinks() {
    const response = await getAllData({
      collectionName: "links",
      id: "social-links",
    });

    if (response.data) {
      setsocialLinks(response.data.data());
    }

    if (response.error) {
    }
  }

  function openLink(link = "") {
    if (link) {
      if (link.startsWith("https")) {
        window.open(link, "_blank");
      } else {
        window.open(`https://${link}`, "_blank");
      }
    }
  }

  return (
    <div className="w-full h-screen relative flex justify-center items-center">
      {location.pathname === "/" ? <WavePlayer /> : null}
      <div className="p-2 absolute top-0 w-full h-full z-50">
        <Header />
        <Outlet />
      </div>
      <motion.div
        initial={{ translateX: 400 }}
        animate={{ translateX: getmenu ? 0 : 245 }}
        transition={{
          ease: "linear",
        }}
        className="fixed z-50 right-0 top-1/3 flex justify-start items-center"
      >
        <div className="flex flex-col justify-center items-center">
          <div
            onClick={() => setmuteSpeaker(_instance.mute().isMuted)}
            className="w-14 h-14 cursor-pointer button flex justify-center items-center  rounded-md"
          >
            <motion.div initial={{ scale: 1 }} whileHover={{ scale: 1.1 }}>
              {muteSpeaker ? (
                <FaVolumeXmark fontSize={"24px"} className="text-color" />
              ) : (
                <FaVolumeHigh fontSize={"24px"} className="text-color" />
              )}
            </motion.div>
          </div>
          <div
            onClick={() => setgetmenu(!getmenu)}
            className="w-14 h-14 cursor-pointer button flex justify-center items-center  rounded-md"
          >
            <motion.div
              initial={{ rotateZ: 0 }}
              animate={{ rotateZ: [0, 180, 360] }}
              transition={{ duration: 1.8, ease: "linear", repeat: Infinity }}
            >
              <MdSettings fontSize={"24px"} className="text-color" />
            </motion.div>
          </div>
        </div>
        <div className="w-60 h-72 outer-shadow p-2 rounded-md bg-greyLight flex justify-start items-center flex-col">
          <div className="font-bold text-sm text-color tracking-wider">
            Switch To Portfolio
          </div>
          <div className="outer-shadow h-8 w-auto flex my-2 border-2 rounded-md justify-center items-center">
            <div
              onClick={() => settoggleTab("1")}
              className={`${
                toggleTab == "1"
                  ? "inner-shadow active rounded-s-md"
                  : "outer-shadow"
              } cursor-pointer h-full font-bold px-4 justify-center items-center flex text-sm py-1 tracking-wider`}
            >
              current
            </div>
            <div
              onClick={() => settoggleTab("2")}
              className={`${
                toggleTab == "2" ? "inner-shadow active" : "outer-shadow"
              } cursor-pointer h-full px-4 font-bold justify-center items-center flex text-sm tracking-wider`}
            >
              v3.O
            </div>
            <div
              onClick={() => settoggleTab("3")}
              className={`${
                toggleTab == "3"
                  ? "inner-shadow active rounded-e-md"
                  : "outer-shadow"
              } cursor-pointer h-full px-4 font-bold justify-center items-center flex text-sm tracking-wider`}
            >
              v2.O
            </div>
          </div>
          {/* <div className="font-bold text-sm text-color my-2 tracking-wider">
            Switch web color
          </div>
          <div className="flex justify-center items-center gap-2">
            <div className="shadow bg-[var(--greyLight-1)] w-12 h-12 border-2 cursor-pointer"></div>
            <div className="shadow bg-[var(--gray)] w-12 h-12 border-2 cursor-pointer"></div>
          </div> */}
          <div className="my-2 w-full h-full">
            <div className="">
              <Text
                text={"Songs Queue"}
                isAnimate={true}
                hovereffect={true}
                classname={
                  "capitalize font-bold text-base stroke-[4px] text-color"
                }
              />
            </div>
            <div className="w-full mt-2 h-full overflow-auto">
              {[].map((value, index) => (
                <div
                  key={index}
                  className="text-sm cursor-pointer border-b py-1 border-slate-400"
                >
                  {value?.name}
                </div>
              ))}

              <div className="text-sm text-color uppercase">
                under developemnt...
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <div className="fixed rounded-md gap-2 flex-col flex justify-center bg-transparent items-center cursor-pointer z-50 left-0  h-full">
        <motion.div
          initial={{ visibility: "hidden" }}
          animate={{ visibility: "visible" }}
          transition={{ delay: 0.4 }}
          className="outer-shadow border-2"
        >
          {socialLinks.website && (
            <motion.div
              onClick={() => openLink(socialLinks.website)}
              initial={{ x: -100, display: "none" }}
              animate={{ x: 0, display: "flex" }}
              transition={{ delay: 1.0 }}
              className="w-14 h-14 flex group justify-center  items-center button"
            >
              <TbWorldWww
                fontSize={"24px"}
                className="group-hover:text-blue-600 text-color"
              />
            </motion.div>
          )}
          {socialLinks.linkedin && (
            <motion.div
              onClick={() => openLink(socialLinks.linkedin)}
              initial={{ x: -100, display: "none" }}
              animate={{ x: 0, display: "flex" }}
              transition={{ delay: 1.2 }}
              className="w-14 h-14 flex group justify-center  items-center button"
            >
              <FaLinkedinIn
                fontSize={"24px"}
                className="group-hover:text-[#0072b1] text-color"
              />
            </motion.div>
          )}
          {socialLinks.github && (
            <motion.div
              onClick={() => openLink(socialLinks.github)}
              initial={{ x: -100, display: "none" }}
              animate={{ x: 0, display: "flex" }}
              transition={{ delay: 1.4 }}
              className="w-14 h-14 flex group justify-center items-center button"
            >
              <FaGithub
                fontSize={"24px"}
                className="group-hover:text-[#24292e] text-color"
              />
            </motion.div>
          )}
          {socialLinks.instagram && (
            <motion.div
              onClick={() => openLink(socialLinks.instagram)}
              initial={{ x: -100, display: "none" }}
              animate={{ x: 0, display: "flex" }}
              transition={{ delay: 1.5 }}
              className="w-14 group h-14 flex justify-center items-center button"
            >
              <FaInstagram
                fontSize={"24px"}
                className="group-hover:text-[#962fbf] text-color"
              />
            </motion.div>
          )}
          {socialLinks.x && (
            <motion.div
              onClick={() => openLink(socialLinks.x)}
              initial={{ x: -100, display: "none" }}
              animate={{ x: 0, display: "flex" }}
              transition={{ delay: 1.6 }}
              className="w-14 group h-14 flex justify-center items-center button"
            >
              <FaXTwitter
                fontSize={"24px"}
                className="group-hover:text-black text-color"
              />
            </motion.div>
          )}
          {socialLinks.discord && (
            <motion.div
              onClick={() => openLink(socialLinks.discord)}
              initial={{ x: -100, display: "none" }}
              animate={{ x: 0, display: "flex" }}
              transition={{ delay: 1.8 }}
              className="w-14 group h-14 flex justify-center items-center button"
            >
              <FaDiscord
                fontSize={"24px"}
                className="group-hover:text-[#7289da] text-color"
              />
            </motion.div>
          )}
        </motion.div>
      </div>

      {location.pathname === "/" ? (
        <motion.div
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          className="fixed bottom-0 z-50  left-0 right-0 w-full flex justify-center items-center text-color"
        >
          <div className="outer-shadow py-4 px-8 font-bold tracking-wider">
            Click the above image.
          </div>
        </motion.div>
      ) : null}
    </div>
  );
};

export default memo(Layout);
