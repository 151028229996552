import React, { memo, useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import image from "../../assets/image/1652293810859.jpeg";
import _instance from "../../utils/Web.player";

// song import
import Dancing_With_Your_Ghost from "../../assets/music/Dancing_With_Your_Ghost.mp3";

import "./style.scss";

_instance.loadTrack({ track: Dancing_With_Your_Ghost });

const WavePlayer = () => {
  const [animationToggle, setanimationToggle] = useState(false);
  const controls = useAnimationControls();

  useEffect(() => {
    return () => {
      _instance.pause();

      if (animationToggle) {
        handleAnimate();
      }
    };
  }, []);

  _instance.onPause(() => {
    setanimationToggle(false);
    controls.stop();
  });

  _instance.onPlaySong(() => {
    setanimationToggle(true);
  });

  const handleAnimate = async () => {
    if (!animationToggle) {
      controls.start(
        { rotate: [0, 180, 360] },
        { duration: 3.5, ease: "linear", repeat: Infinity }
      );
      _instance.play();
    } else {
      controls.stop();
      _instance.pause();
    }
    setanimationToggle(!animationToggle);
  };

  return (
    <div className="circle">
      <motion.div
        initial={{ rotate: 0 }}
        transition={{
          repeat: animationToggle ? Infinity : "",
          duration: 8,
          ease: "linear",
        }}
        animate={controls}
        className="circle__btn overflow-hidden paused p-2"
        onClick={handleAnimate}
      >
        {/* <ion-icon className="pause" name="pause"></ion-icon>
      <ion-icon className="play" name="play"></ion-icon> */}
        <img src={image} width={"100%"} className="rounded-full" />
      </motion.div>
      <span
        className={`circle__back-1 ${animationToggle ? " " : " paused"}`}
      ></span>
      <span
        className={`circle__back-2 ${animationToggle ? " " : " paused"}`}
      ></span>
      {/* <span className="circle__back-3"></span> */}
    </div>
  );
};

export default memo(WavePlayer);
