import React from "react";
import { motion } from "framer-motion";

const Text = ({ hovereffect, text = "", isAnimate, classname }) => {
  function seperateText() {
    let newTextArray = [];
    if (text?.length) {
      const arrayText = text?.split("");
      arrayText.forEach((value) => {
        newTextArray.push(
          <span className="hover:text-[var(--primary-dark)] cursor-pointer">
            {value}
          </span>
        );
      });
    }

    return newTextArray;
  }

  return isAnimate ? (
    <motion.div
      initial={{ y: -300 }}
      animate={{ y: 0 }}
      className={classname + " text"}
    >
      {hovereffect ? seperateText().map((value) => value) : text}
    </motion.div>
  ) : (
    <div className={classname + " text"}>{text}</div>
  );
};

export default Text;
