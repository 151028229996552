import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { app } from "./init";

const db = getFirestore(app);

export const getAllData = async ({ collectionName, id }) => {
  if (!collectionName) return { error: "please pass collection name." };
  if (!id) return { error: "please pass id." };
  try {
    const data = await getDoc(doc(db, collectionName, id));
    return { data };
  } catch (error) {
    return { error };
  }
};

export async function getDatabyCollection({ collectionName }) {
  if (!collectionName) return { error: "please pass collection name." };
  try {
    const data = await getDocs(collection(db, collectionName));
    return { data };
  } catch (error) {
    return { error };
  }
}
