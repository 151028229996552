import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Text from "../text/Text";

const Header = () => {
  const nav = [
    { label: "home", link: "/" },
    { label: "projects", link: "/projects" },
    { label: "about", link: "/about" },
  ];

  const path = useLocation();

  return (
    <div className="header flex w-full p-4 justify-between items-center sticky top-0">
      <Text
        text={"react.dev"}
        isAnimate={true}
        classname={"uppercase font-bold text-5xl stroke-[4px] text-greyLight"}
      />
      <div className="lg:flex hidden justify-center items-end gap-3">
        {nav.map((value, index) => {
          return (
            <motion.div
              key={index}
              className={`${
                path.pathname === value.link ? "active" : "text-color"
              } button rounded-md transition-shadow`}
              initial={{ opacity: 0, y: -300 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <NavLink to={value.link}>
                <button
                  key={index}
                  className="py-2 px-5 font-medium  capitalize rounded-md flex justify-center items-center cursor-pointer"
                >
                  {value.label}
                </button>
              </NavLink>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
