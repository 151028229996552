import React, { memo } from "react";

const Card = ({ desc, tags = [], name }) => {
  return (
    <div className="w-96 outer-shadow p-2 rounded-md">
      <div className="my-1 font-bold text-lg">{name}</div>
      <div className="text-sm">{desc}</div>
      <div className="w-full flex justify-start items-center mt-4 mb-2 gap-2">
        {tags.map((value) => {
          return (
            <div className="border shadow py-1 capitalize rounded-md text-sm px-3">
              {value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Card);
