class WebAudioPlayer {
  trackList = [];
  track = null;
  audioContext = null;
  audioElement = null;
  currentTime = null;
  pitchNode = null;
  trackPosition = 0;
  isPlaying = false;
  isPause = false;
  isAutoplay = false;
  defaultVolume = 1;
  volume = 1;
  isPlayerLoaded = false;
  isMuted = false;

  //   check if browser can play audio or not
  browserSupport = false;

  constructor() {
    this.trackPosition = 0;
    this.isPlaying = false;
    this.isAutoplay = true;
    this.audioElement = new Audio();
    this.audioContext = new AudioContext(this.audioElement);

    this.audioElement.autoplay = false;
    this.audioElement.volume = this.defaultVolume;

    window.audioElement = this.audioElement;
    window.audioContext = this.audioContext;
  }

  createPlayer() {
    this.audioElement = new Audio();
    this.audioContext = new AudioContext(this.audioElement);

    this.audioContext.destination.connect();

    if (this.audioContext) {
      return {
        status: 200,
        message: "Player Creeated!",
        track: this.track,
        playlist: this.trackList,
        isPlaying: this.isPlaying,
        isPause: this.isPause,
        isAutoplay: this.isAutoplay,
      };
    }
  }

  pause() {
    this.isPlaying = false;
    this.isPause = true;
    this.audioElement.pause();
    return {
      isPause: this.isPause,
      isPlaying: this.isPlaying,
      currentTrack: this.track,
      currentTime: this.audioElement.currentTime,
    };
  }

  play() {
    if (!this.isPlaying) {
      const response = this.audioElement.play();
      this.isPlaying = true;
      this.isPause = false;
      response
        .then((r) => {
          console.log("r: ", r);
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }

    return {
      isPause: this.isPause,
      isPlaying: this.isPlaying,
      currentTrack: this.track,
      duration: this.audioElement.duration,
    };
  }

  loadTrack({ track, autoplay }) {
    if (this.audioElement) {
      this.audioElement.src = track;
      this.track = track;

      this.audioElement.autoplay = autoplay;
      this.isAutoplay = autoplay;

      return {
        message: "track is loaded into player.",
        status: 200,
        player: this.audioElement,
        isAutoplay: this.isAutoplay,
      };
    } else {
      return { error: "audio player is not created.", status: 404 };
    }
  }

  setVolume(value) {
    if (value > 0 && value <= this.defaultVolume) {
      const convertValue = value / 100;
      this.volume = convertValue;
      this.audioElement.volume = convertValue;
    } else {
      return {
        error: "Max Value can not be greater than " + this.defaultVolume * 100,
      };
    }
  }

  mute() {
    this.isMuted = !this.isMuted;
    this.audioElement.muted = this.isMuted;

    return {
      isPlaying: this.isPlaying,
      isMuted: this.isMuted,
      volume: this.volume,
    };
  }

  addTrackToQueue({ track }) {
    if (this.isPlaying && !this.isPause) {
      this.trackList.push(track);
    } else if (!this.isPlaying && this.trackList.length) {
      this.trackList.push(track);
    }
    if (!this.isPlaying && !this.trackList.length) {
      this.track = track;
      this.loadTrack({ track, autoplay: this.isAutoplay });
      this.play();
    }
  }

  updateTrackPosition() {
    if (this.trackPosition < this.trackList.length) {
      this.trackPosition = this.trackPosition + 1;
    }
    if (this.isAutoplay && this.trackList.length === this.trackPosition) {
      this.trackPosition = 0;
    }
  }

  // callback function
  onPlaySong(playCallback) {
    this.audioElement.onplay = (e) => {
      playCallback({
        ...e,
        trackPosition: this.trackPosition,
        queue: this.trackList,
        currentTrack: this.track,
        isPlaying: this.isPlaying,
      });
      if (this.isPlaying) {
        this.trackList.splice(0, 1);
        // this.updateTrackPosition();
      }
    };
  }

  onTimeChange(durationCallback) {
    this.audioElement.ontimeupdate = (e) => {
      durationCallback({ ...e });
    };
  }

  onPause(pauseCallback) {
    this.audioElement.onpause = (e) => {
      pauseCallback({ ...e, queue: this.trackList });
      if (this.trackList.length) {
        this.loadTrack({
          track: this.trackList[this.trackPosition],
          autoplay: this.isAutoplay,
        });
        this.play();
      }
    };
  }

  onVolumeChange(volumeCallback) {
    this.audioElement.onvolumechange = (e) => {
      volumeCallback({
        ...e,
        isPause: this.audioElement.paused,
        isAutoplay: this.audioElement.autoplay,
        isPlaying: this.isPlaying,
      });
    };
  }
}

const _instance = new WebAudioPlayer();
export default _instance;
