import React, { memo, useEffect, useState } from "react";
import Text from "../components/text/Text";
import Objects from "../components/objects/Objects";
import Timeline from "../components/timeline/Timeline";
import { getAllData, getDatabyCollection } from "../firebase/firemethods";
import parse from "html-react-parser";

const About = () => {
  const [about, setAbout] = useState("");
  const [companies, setcompanies] = useState([]);

  useEffect(() => {
    retriveData();
  }, []);

  async function retriveData() {
    const { data, error } = await getAllData({
      collectionName: "about",
      id: "about-id",
    });
    setAbout(data.data().about);
    setcompanies([]);

    const companiesData = await getDatabyCollection({
      collectionName: "companies",
    });
    companiesData.data.forEach((doc) => {
      setcompanies((prev) => [...prev, { id: doc.id, ...doc.data() }]);
    });
  }

  return (
    <div className="Py-4 px-14 w-full h-full flex-col flex justify-start items-start">
      <div className="w-full text-center my-4">
        <Text
          classname={"font-bold !text-6xl text-greyLight"}
          text={"About"}
          isAnimate={true}
        />
      </div>
      <div className="mx-auto text-base text-slate-400 md:w-[700px] w-10/12">
        {parse(about)}
      </div>
      <div className="flex gap-4 w-full justify-center items-center">
        <div className="outer-shadow border-2 rounded p-2 my-4"></div>
        <div className="outer-shadow border-2 rounded p-2 my-4"></div>
        <div className="outer-shadow border-2 rounded p-2 my-4"></div>
      </div>
      <div className="w-full text-center my-4">
        <Text
          classname={"font-bold !text-6xl text-greyLight tracking-wider"}
          text={"Experience"}
          hovereffect={true}
          isAnimate={true}
        />
      </div>
      <div className="w-full px-4 py-2">
        <div className="mx-auto w-11/12 py-3">
          <Objects isAnimate={true} />
        </div>

        <div className="w-full flex justify-center items-start">
          <Timeline data={companies || []} />
        </div>
      </div>
    </div>
  );
};

export default memo(About);
