import React, { memo } from "react";
import { motion } from "framer-motion";

const Objects = ({ shape, isAnimate }) => {
  return (
    <motion.div
      whileHover={{
        borderRadius: isAnimate ? ["20%", "20%", "50%", "50%", "20%"] : null,
      }}
      transition={{ repeat: Infinity, duration: 3.2, ease: "easeInOut" }}
      className={`w-32 h-32 outer-shadow p-8 ${
        shape === "circle" ? "rounded-full" : "rounded-sm"
      }`}
    >
      <motion.div
        whileHover={{
          borderRadius: isAnimate ? ["20%", "20%", "50%", "50%", "20%"] : null,
        }}
        transition={{ repeat: Infinity, duration: 3.2, ease: "easeInOut" }}
        className={`inner-shadow w-full h-full ${
          shape === "circle" ? "rounded-full" : "rounded-sm"
        }`}
      ></motion.div>
    </motion.div>
  );
};

export default memo(Objects);
