import React from "react";
import "./timeline.scss";
import moment from "moment";

const Timeline = ({ data = [] }) => {
  return (
    <ul className="before:border-2">
      {data.map((value) => {
        return (
          <li key={value?.id}>
            <div className="date outer-shadow text-color rounded-full">
              {moment(value?.from).format("MMM YYYY")} -{" "}
              {value?.to ? moment(value?.to).format("MMM YYYY") : "Present"}
            </div>
            <div className="title !text-lg !font-bold">{value?.name}</div>
            <div className="descr text-sm font-medium">{value?.desc}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default Timeline;
