import React from "react";
import Layout from "./components/layout/layout";
import { Route, Routes } from "react-router-dom";
import Projects from "./view/projects";
import About from "./view/about";

function App() {
  return (
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<Projects />} path="/projects" />
        <Route element={<About />} path="/about" />
      </Route>
    </Routes>
  );
}

export default App;
